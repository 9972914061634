<template>
  <div v-loading="loading" class="h-full w-full">
    <image-viewer
      v-if="images"
      class="h-full w-full"
      image-key="original"
      thumbnail-key="1x"
      image-upload-id="transactionId"
      :images="images"
      image-uploader
      @close-images="$emit('close-images')"
      @cancel-requested="$emit('close-images')"
      @add-image="addImage"
      @remove-image="removeImage"
    />
  </div>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import get from 'just-safe-get'
import { NOTIFICATIONS_TYPES } from '@/constants'
import ImageViewer from '@/components/image-viewer'
import { useAssetsStore } from '@/store/assets'
import { useMessagesStore } from '@/store/messages'

export default {
  components: {
    ImageViewer
  },
  props: {
    transaction: {
      type: Object,
      required: true
    },
    imageViewerOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      images: null,
      correlationId: null
    }
  },
  computed: {
    imageUploadFinished() {
      if (!this.correlationId) return false
      return (
        useMessagesStore().getGlobalMessages.find(
          (message) => message.id === this.correlationId
        )?.operation?.type === NOTIFICATIONS_TYPES.SUCCESS ?? false
      )
    }
  },
  watch: {
    imageUploadFinished(isSuccess) {
      if (isSuccess) {
        this.getImages()
      }
    }
  },
  async created() {
    await this.getImages()
  },
  methods: {
    async getImages() {
      this.loading = true
      try {
        const { data } = await th
          .transactionsV2()
          .getImages(this.transaction.id)
        this.images = data.reduce((images, item, index) => {
          if (item.images !== null) {
            images.push({ ...item, index })
          }
          return images
        }, [])
      } catch (err) {
        const errorCode = get(err, 'properties.error.response.status')
        // If there are no images, the API responds with error 404 - but we don't want to treat it as an error.
        if (errorCode !== 404) {
          this.$logException(err, { trackError: false })
        }
        this.images = []
      } finally {
        this.loading = false
      }
    },
    async removeImage({ image, index }) {
      try {
        await this.$confirm(
          this.$t('common.delete_confirm.content', {
            objectName: `'${this.$t('common.forms.labels.image')}'`
          }),
          this.$t('common.titles.warning'),
          {
            confirmButtonText: this.$t('common.interactions.buttons.ok'),
            cancelButtonText: this.$t('common.interactions.buttons.cancel'),
            type: 'warning'
          }
        )
        this.loading = true

        try {
          await th
            .transactionsV2()
            .deleteImage(this.transaction.id, image.index)
          this.getImages()
        } catch (err) {
          this.images = []
          this.$logException(err, {
            message: this.$t(
              'components.image_upload.error_message.remove_fail'
            )
          })
        } finally {
          this.loading = false
        }
      } catch (err) {
        // no-op
        return
      }
    },
    async addImage(file) {
      this.loading = true
      try {
        // Displaying the uploaded image to the user although it wasn't yet sent to the API
        const localImage = URL.createObjectURL(file.raw)

        const bodyFormData = new FormData()
        bodyFormData.append('image', file.raw, file.raw.name)

        const { data } = await th
          .transactionsV2()
          .createImage(this.transaction.id, bodyFormData)

        const assetId = data?.[0]?.correlationId
        if (!assetId) {
          throw new Error(`Response data or correlation ID is missing`)
        }
        this.correlationId = assetId

        const assetStore = useAssetsStore()
        assetStore.setNewAsset(assetId, {
          date: new Date(),
          originKey: 'pages.transactions.all.images.title',
          text: {
            success: this.$t('notifications.assets.success.text', {
              receipt_number: this.transaction.custom_id
            }),
            loading: this.$t('notifications.assets.loading.text', {
              entity: this.$t('pages.transactions.all.images.title')
            }),
            error: this.$t('notifications.assets.error.text', {
              receipt_number: this.transaction.custom_id
            })
          }
        })

        this.getImages()
      } catch (err) {
        this.$logException(err, {
          message: this.$t('components.image_upload.error_message.create_fail')
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
