<template>
  <div class="flex flex-col h-full justify-between p-6">
    <div class="py-2 text-xl text-th-primary text-center relative">
      <!-- Close button -->
      <el-button
        class="text-th-primary absolute top-0 left-0"
        text
        @click="handleClose"
      >
        <el-icon class="text-2xl"><Close /></el-icon>
      </el-button>

      <!-- Title -->
      <div class="font-bold">
        {{ $t('components.image-viewer.title') }}
      </div>
    </div>

    <!-- Image display -->
    <img
      v-if="currentImageSrc"
      class="m-auto max-w-full rounded block"
      :src="currentImageSrc"
    />

    <!-- Empty -->
    <div
      v-else
      class="h-full pt-6 text-center my-0 flex flex-col justify-center items-center"
    >
      <img class="max-w-full" :src="emptyImage" />
      <div
        class="text-lg font-bold w-56 pt-6"
        :class="{ italic: !isWhiteLabel }"
      >
        {{ $t('components.image-viewer.images.empty.text') }}
      </div>
    </div>

    <div class="pt-4">
      <hr class="pb-4" />
      <!-- Instructions -->
      <div
        v-if="!currentImageSrc"
        class="w-full text-center text-sm pb-6"
        :class="{ italic: !isWhiteLabel }"
      >
        <div class="font-bold">
          {{ $t('components.image-viewer.upload.instructions.text') }}
        </div>
        <div>
          {{ $t('components.image-viewer.upload.instructions.limit') }}
        </div>
      </div>

      <div class="grid gap-2 grid-cols-4">
        <!-- Image uploader -->
        <el-upload
          v-if="showUploader"
          drag
          action="#"
          class="th-thumbnail-size border-2 border-th-secondary hover:border-th-secondary-blue border-dashed cursor-pointer rounded"
          data-testid="image-uploader"
          :show-file-list="false"
          :on-change="onChange"
          :auto-upload="false"
          multiple
          accept="image/png, image/jpeg"
        >
          <div class="h-full flex justify-center items-center">
            <img :src="addImage" class="w-8 h-8" />
          </div>
        </el-upload>

        <!-- Uploaded images preview -->
        <div
          v-for="(image, index) in images"
          :key="index"
          :class="{ 'border-th-secondary': currentIndex === index }"
          class="th-thumbnail-size relative border-2 rounded cursor-pointer overflow-hidden"
          @click="selectImage(index)"
        >
          <el-button
            class="absolute top-0 right-0 mt-1 mr-1 button-mini"
            type="danger-icon"
            plain
            round
            icon="Delete"
            @click="removeImage(image, index)"
          />
          <img
            class="h-full w-full object-cover"
            :src="getImageSrc(image, thumbnailKey)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import get from 'just-safe-get'
import mem from 'mem'
import addImage from '@/assets/illustrations/add-image.svg'
import emptyImage from '@/assets/illustrations/upside-down-ghost.svg'
import { isUnifiedCommerce } from '@/constants'

const IMG_SIZE_LIMIT = 2500

export default {
  name: 'ImageViewer',
  props: {
    open: {
      type: Boolean,
      default: false
    },
    images: {
      type: Array,
      default: () => []
    },
    imageKey: {
      type: String,
      default: 'original'
    },
    thumbnailKey: {
      type: String,
      default: '1x'
    },
    imageUploader: {
      type: Boolean,
      default: false
    },
    imageUploadId: {
      type: String,
      default: undefined
    },
    thumbnailDelete: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      addImage,
      emptyImage,
      currentIndex: 0
    }
  },
  computed: {
    currentImage() {
      return this.images[this.currentIndex]
    },
    showUploader() {
      return this.imageUploader
    },
    currentImageSrc() {
      return this.getImageSrc(this.currentImage, this.imageKey)
    },
    isWhiteLabel() {
      return isUnifiedCommerce()
    }
  },
  methods: {
    removeImage(image, index) {
      this.$emit('remove-image', { image, index })
    },
    selectImage(index) {
      this.currentIndex = index
    },
    getImageSrc: mem(
      (value, imageKey) => get(value, ['images', imageKey], ''),
      { cacheKey: JSON.stringify }
    ),
    onChange(file) {
      if (!this.isValid(file.raw)) return
      this.$emit('add-image', file)
    },
    handleClose() {
      this.$emit('close-images')
    },
    isValid(file) {
      const isValidSize = file.size / 1024 < IMG_SIZE_LIMIT
      if (!isValidSize) {
        this.$message.error(
          this.$t('components.image-viewer.upload.errors.size')
        )
      }
      return isValidSize
    }
  }
}
</script>

<style scoped>
.th-thumbnail-size {
  height: 75px;
}

.th-thumbnail-size :deep(.el-upload),
.th-thumbnail-size :deep(.el-upload-dragger) {
  border: unset;
  height: 100%;
  width: 100%;
}
</style>
