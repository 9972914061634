<template>
  <div v-loading="loading">
    <el-row
      v-if="!!payload && (row.type === 'sale' || row.type === 'sale_cancel')"
      :key="loading"
      :gutter="20"
    >
      <el-col :span="20">
        <el-row
          v-for="item in payload.items"
          :key="item.id"
          class="font-bold mb-2"
        >
          <el-col :span="1">
            <div
              class=""
              v-text="
                Number.isFinite(item.qty)
                  ? $formatNumber(item.qty, { dynamicPrecision: true })
                  : '-'
              "
            />
          </el-col>

          <el-col :span="5">
            <div class="w-full overflow-hidden truncate">
              {{ item.name || '-' }}
            </div>
          </el-col>

          <el-col :span="3">
            <div
              class="text-right"
              v-text="localizeCurrency(item.amount_net, item.currency)"
            />
          </el-col>

          <el-col :span="3">
            <div
              class="text-right"
              v-text="localizeCurrency(item.tax_amount, item.currency)"
            />
          </el-col>

          <el-col :span="2">
            <div class="text-right text-gray-500 font-normal">
              {{ (item.vat_rate || 0).toLocaleString(locale) }}%
            </div>
          </el-col>

          <el-col :span="3">
            <div class="text-right">
              <span
                v-text="localizeCurrency(item.amount_unit_gross, item.currency)"
              />
            </div>
          </el-col>

          <el-col :span="3">
            <div
              class="text-right text-gray-500 font-normal"
              v-text="localizeCurrency(item.discount_amount, item.currency)"
            />
          </el-col>

          <el-col :span="3">
            <div
              class="text-right"
              v-text="localizeCurrency(item.amount_gross, item.currency)"
            />
          </el-col>
        </el-row>

        <el-row v-if="!!payload" class="sub-table-row">
          <el-col :span="6">
            <div
              class="mb-1"
              v-text="
                $t('pages.transactions.all.table.subtable.total_discounts')
              "
            />
            <div
              class="mb-1"
              v-text="$t('pages.transactions.all.table.subtable.total_tax')"
            />
            <div
              class="font-bold mb-2"
              v-text="
                $t('pages.transactions.all.table.subtable.total').toUpperCase()
              "
            />
          </el-col>
          <el-col :span="3" class="text-right">
            <div
              class="mb-1"
              v-text="
                localizeCurrency(payload.discount_amount_total, row.currency)
              "
            />
            <div
              class="mb-1"
              v-text="localizeCurrency(payload.tax_amount_total, row.currency)"
            />
            <div
              class="mb-1 font-bold"
              v-text="localizeCurrency(payload.total, row.currency)"
            />
          </el-col>
        </el-row>

        <el-row
          v-if="payload && payload.payments && payload.payments.length"
          class="sub-table-row"
        >
          <el-row
            v-for="payment in payload.payments"
            :key="payment.id"
            class="w-full"
          >
            <el-col :span="6">
              <div class="mb-1" v-text="paymentTypeMap[payment.type]" />
            </el-col>

            <el-col :span="3">
              <div
                class="mb-1 text-right"
                v-text="
                  localizeCurrency(payment.amount_total, payment.currency)
                "
              />
            </el-col>
          </el-row>
        </el-row>
        <el-row v-if="Number.isFinite(payload.change) && payload.change !== 0">
          <el-col :span="6">
            <div
              class="mb-1"
              v-text="$t('pages.transactions.all.table.subtable.change')"
            />
          </el-col>

          <el-col :span="3">
            <div
              class="mb-1 text-right"
              v-text="localizeCurrency(payload.change, payload.currency)"
            />
          </el-col>
        </el-row>
      </el-col>

      <el-col :key="loading" :span="4" class="actions-column">
        <el-button
          :disabled="!payload || !receipts || !receipts.length"
          text
          class="button-mini"
          @click="openReceiptViewer"
          v-text="
            $t('pages.transactions.all.table.subtable.actions.show_receipt')
          "
        />
        <el-button
          :disabled="!payload || !receipts || !receipts.length"
          text
          class="button-mini"
          @click="openReceiptEmailer"
          v-text="
            $t('pages.transactions.all.table.subtable.actions.email_receipt')
          "
        />
        <el-button
          disabled
          text
          class="button-mini"
          v-text="
            $t('pages.transactions.all.table.subtable.actions.print_receipt')
          "
        />
        <el-button
          disabled
          text
          class="button-mini"
          v-text="
            $t('pages.transactions.all.table.subtable.actions.print_quittance')
          "
        />
      </el-col>
    </el-row>
    <!-- END OF carts -->

    <!-- BEGIN OF expenses -->
    <el-row v-else-if="payload && row.type === 'expense'">
      <el-col :span="16">
        {{ $t('pages.transactions.all.table.subtable.expenses.account') }}:
        {{ (payload.expense_account && payload.expense_account.name) || '-' }}
        {{ `(${payload.expense_account.custom_number || '-'})` }}
      </el-col>

      <el-col :key="loading" :span="8" class="actions-column">
        <el-button
          text
          class="button-mini"
          :disabled="!payload || !receipts || !receipts.length"
          @click="openReceiptViewer"
          v-text="
            $t('pages.transactions.all.table.subtable.actions.show_receipt')
          "
        />
        <el-button
          text
          class="button-mini"
          :disabled="!payload || !receipts || !receipts.length"
          @click="openImageViewer"
          v-text="
            $t('pages.transactions.all.table.subtable.actions.show_images')
          "
        />
      </el-col>
    </el-row>

    <div
      v-else-if="!loading"
      v-text="$t('pages.transactions.all.table.subtable.no_cartitems')"
    />
    <Teleport to="#th-modals">
      <receipt-emailer
        v-if="receiptEmailerOpen"
        :open="receiptEmailerOpen"
        :receipts="receipts"
        :resource-id="row.id"
        :cart="getCart()"
        :lines="getLines()"
        :date="row.date"
        :types="['customer']"
        show-search
        search-resource="customers"
        :default-customer-email="customer.email"
        :default-customer-name="customerFullName"
        :search-result-formatter="
          (item) => $formatCustomer(item, { showEmail: true })
        "
        @close-emailer="closeReceiptEmailer"
        @cancel-emailer="closeReceiptEmailer"
        @email-sent="closeReceiptEmailer"
      />
    </Teleport>
  </div>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import pick from 'just-pick'
import safeGet from 'just-safe-get'

import ReceiptEmailer from '@/components/tillhub/receipt-emailer'
import fullName from '@/utils/full-name'

export default {
  components: {
    ReceiptEmailer
  },
  props: {
    row: {
      type: Object,
      required: false,
      default: undefined
    }
  },
  data() {
    return {
      loading: false,
      payload: null,
      customer: {},
      receiptViewerOpen: false,
      receiptEmailerOpen: false,
      imageViewerOpen: false,
      paymentTypeMap: {
        card: this.$t(
          'pages.transactions.all.table.subtable.payment_types.card'
        ),
        cash: this.$t(
          'pages.transactions.all.table.subtable.payment_types.cash'
        ),
        expense: this.$t(
          'pages.transactions.all.table.subtable.payment_types.expense'
        ),
        voucher: this.$t(
          'pages.transactions.all.table.subtable.payment_types.voucher'
        ),
        custom: this.$t(
          'pages.transactions.all.table.subtable.payment_types.custom'
        ),
        invoice: this.$t(
          'pages.transactions.all.table.subtable.payment_types.invoice'
        ),
        card_opi: this.$t(
          'pages.transactions.all.table.subtable.payment_types.card_opi'
        ),
        card_concardis: this.$t(
          'pages.transactions.all.table.subtable.payment_types.card_concardis'
        ),
        card_adyen: this.$t(
          'pages.transactions.all.table.subtable.payment_types.card_adyen'
        ),
        card_tim: this.$t(
          'pages.transactions.all.table.subtable.payment_types.card_tim'
        )
      }
    }
  },
  computed: {
    locale() {
      return this.$store.getters['Config/getLocale']
    },
    receipts() {
      if (!this.payload) return null
      return this.payload.receipts
    },
    customerFullName() {
      return this.customer ? fullName(this.customer) : null
    }
  },
  created() {
    this.$emitter.on('close-receipts', this.closeDialog)
  },
  mounted() {
    // make this super deferred for perf
    this.$nextTick(async () => {
      await this.fetch()
      this.fetchCustomer()
    })
  },
  methods: {
    async fetch() {
      const errMessage = this.$t(
        'pages.transactions.all.table.subtable.errors.fetch5xx.message'
      )
      try {
        this.loading = true
        const {
          data
        } = await th
          .analyticsHandlers()
          .analytics.reports.AnalyticsReportsTransactionsDetail.get(this.row.id)
        this.$log.debug('tx additionals:', data)
        this.payload = { ...data, receipts: this.sortReceipts(data.receipts) }
      } catch (err) {
        this.$logException(err, {
          trackError: false,
          message: errMessage
        })
      } finally {
        this.loading = false
      }
    },
    sortReceipts(receipts) {
      const orderedList = ['customer', 'merchant']
      return [...receipts].sort(
        (a, b) => orderedList.indexOf(a.type) - orderedList.indexOf(b.type)
      )
    },
    async fetchCustomer() {
      if (this.payload?.customer) {
        const errMessage = this.$t('common.error.action.read.single', {
          resource: this.$t('common.resource.customer.singular')
        })
        try {
          this.loading = true
          const { data } = await th.customersV1().get(this.payload.customer)
          this.customer = data
        } catch (err) {
          this.$logException(err, {
            trackError: false,
            message: errMessage
          })
        } finally {
          this.loading = false
        }
      }
    },
    getCart() {
      const totalGross = this.getTotal('amount_total_gross', false) || 0
      const totalTax = this.getTotal('tax_amount_total', false) || 0
      const currency = this.row.currency || this.row.items[0].currency

      if (!currency) {
        return this.$message({
          type: 'warning',
          message: this.$t(
            'pages.transactions.all.table.subtable.warnings.missing_currency'
          )
        })
      }

      return {
        amount: {
          gross: totalGross,
          net: totalGross - totalTax
        },
        currency: this.row.currency || 'EUR',
        tax: totalTax
      }
    },
    getLines() {
      const lines = []

      if (this.row.code) {
        lines.push({
          ...this.row.code,
          caption: 'tillhub.de'
        })
      }

      if (
        safeGet(this.row, 'status.signature.caption') &&
        safeGet(this.row, 'status.signature.data')
      ) {
        lines.push({
          ...pick(this.row.status.signature, ['caption', 'data']),
          format: 'qr'
        })
      }
      return lines
    },
    receiptsValid() {
      const { receipts } = this.row
      return (
        receipts && receipts.length && receipts.every((receipt) => receipt.text)
      )
    },
    openReceiptEmailer() {
      this.receiptEmailerOpen = true
    },
    closeReceiptEmailer() {
      this.receiptEmailerOpen = false
    },
    openReceiptViewer() {
      this.$emit('open-current-receipts', {
        receipts: this.payload.receipts,
        receiptTitle: this.row.id,
        resourceId: this.row.id
      })
    },
    closeReceiptViewer() {},
    openImageViewer() {
      // we uncessarily safe guard against non present images here. Also..
      // TODO: remove once we picked up a better API logic for fetching images, especially in the multi-image scenario. That did not exist at time of writing
      if (!safeGet(this.payload, 'extended.images')) return

      this.$emit('open-current-images', {
        resourceId: this.row.id,
        transaction: this.payload
      })
    },
    closeImageViewer() {},
    getTotal(name, shouldFormat = true) {
      if (
        !this.row.items ||
        !this.row.items.length ||
        this.row.items.some((item) => !item.currency)
        // TODO: need to handle case when items do not have same currency
      )
        return shouldFormat ? '-' : null

      const sum = this.row.items.reduce((acc, item) => {
        return acc + (item[name] || 0)
      }, 0)

      return shouldFormat
        ? this.localizeCurrency(sum, this.row.items[0].currency)
        : sum
    },
    localizeCurrency(amount = 0, currency) {
      if (!currency) return '-'
      return this.$formatCurrency(amount, currency)
    }
  }
}
</script>

<style scoped>
.sub-table-row {
  border-top: 1px solid var(--border-color);
  padding-top: 0.5rem;
}

.actions-column {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.vat-rate {
  color: grey;
  font-weight: normal;
  margin-left: 10px;
}

.empty::before {
  content: '\200B';
}
</style>
